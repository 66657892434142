.centered {
  text-align: center;
}

.compactForm {
  margin-left: 10px;
}

.compactForm > :first-child {
  display: flex;
  flex-direction: column;
}

.compactForm > :first-child > * {
  width: 100%;
}

.compactForm > :first-child > * > * {
  width: 95%;
}

.compactForm > :first-child > :empty {
  display: none;
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
}

.formSubTitle {
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
}

.columnForm > :first-child {
  display: flex;
  flex-flow: row wrap;
}

.columnForm > * {
  width: 100%;
}

.columnCompactForm {
  width: 30%;
}

.columnCompactForm > :first-child {
  display: flex;
  flex-flow: row;
}

.columnCompactForm > * {
  width: 100%;
}

.row {
  display: flex;
  flex-flow: row;
  gap: 20px;
}

.rowWrap {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}

.additionalInfo {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
}

.additionalInfo > * {
  width: 23%;
}
