.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.userInfo {
  margin-left: 25px;
  margin-bottom: 20px;
}

.chartWrapper {
  width: 100%;
  height: 40vh;
}

.tooltip {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}
