.show {
  margin-left: 20px;
  display: flex;
  flex-flow: column wrap;
}

.centered {
  text-align: center;
}

.compactShow {
  display: flex;
  flex-flow: row wrap;
}

.columnShow {
  display: flex;
  flex-flow: column wrap;
  padding-top: 0px !important;
}

.columnShow > :first-child {
  padding-top: 16px;
}

.columnShow > :first-child > * {
  padding-top: 16px;
}

.financeData {
  display: flex;
  flex-flow: row wrap;
  width: 90%;
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  display: flex;
}

.formSubTitle {
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  display: flex;
  padding-top: 0px !important;
}

button.regAstralButton {
  margin-left: 10px;
  color: #fff;
  background-color: #3e47d5;
}
button.regAstralButton:hover {
  background-color: #5660ff;
}