.tableContainer {
  max-height: 40vh;
}

.horizontalForm {
  display: flex;
  flex-direction: row;
}

.controlPadding {
  padding-top: 10px;
}
