.formControl {
  width: 256px;
}

.downloadButtonContainer {
  display: flex;
  flex-direction: column;
  margin: 16px;
  gap: 12px;
}

.downloadButton {
  width: 256px;
}

.hint {
  font-size: 0.75rem !important;
}

.warning {
  background: #ffcdd2;
  border-radius: 4px;
  color: #e53935;
  font-size: 10px;
  opacity: 0.5;
  padding: 6px 16px;
  text-align: center;
}

.warningContainer {
  margin-top: 8px;
  margin-bottom: 4px;
}

.container {
  display: flex;
  flex-direction: row;
}

.firstColumn {
  max-width: 500px;
}

.secondColumn {
  width: 500px;
  margin: 16px;
  padding-top: 16px;
}
