.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #F2F6FA;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 360px;
  padding: 17px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
}

.logo {
  width: 100%;
  height: auto;
}

.spacer {
  height: 20px;
}
