button.printButton {
    margin-left: 10px;
  }
  
  .iframe {
    position: absolute;
    width: 0;
    height:0;
    border:0;
  }
  