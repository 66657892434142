.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.chartWrapper {
  width: 100%;
  height: 40vh;
}

.tableContainer {
  width: 100%;
}

.floatRight {
  float: right;
}

.dialogTitle > h2 {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overworkRow {
  background-color: red;
}

.underrestRow {
  background-color: red;
}
