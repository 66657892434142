.control {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.editButton {
  float: right;
}

button.closeDialog {
  margin-right: -12px;
  margin-left: 12px;
  margin-top: -8px;
}
