div.inputBackground {
  background-color: rgba(0, 0, 0, 0.04);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 24px 12px 6px 12px;
}

div.file {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.file > span.filename {
  flex: 1;
  overflow: hidden;
}
