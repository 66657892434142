.container {
  display: flex;
  flex-direction: row;
}

.mediaContainer {
  position: relative;
}

.mediaButtonContainer {
  position: absolute;
  bottom: 16px;
  right: 16px;
  left: 16px;
}


.firstColumn {
  display: flex;
  flex-direction: column;
  width: 690px;
}

.firstColumn .mediaColumn {
  padding-left: 16px;
}

.mediaColumn {
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-right: 16px;
  height: 100%;
}

.mediaColumnPreviews {
  display: flex;
  flex-flow: row wrap;
}

.mediaColumnPreviews [class*="Image_container"],
.mediaColumnPreviews [class*="Video_container"] {
  height: 12vw;
  width: 12vw;
}

.mediaColumnPreviews [class*="Video_container"] video {
  height: auto;
  width: auto;
  padding: 6px;
}

.info {
  color: green;
}

.warning {
  color: #ffa700;
}

.danger {
  color: red;
}

.notAdmittedRow {
  background-color: #ffebee;
}

.notAdmittedRow:hover {
  background-color: #ffcdd2 !important;
}

.tableContainer {
  margin-top: 8px;
  margin-bottom: 8px;
}

.tableContainer th,td {
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.tableContainer th.smallColumn {
  width: 80px;
}

.nonAdmissionReasonsList {
  margin: 0px;
}

button.admissionButton {
  margin-left: 10px;
  color: #fff;
  background-color: #009100;
  margin-right: 88px;
}
button.admissionButton:hover {
  background-color: #00bf00;
}

button.nonAdmissionButton {
  margin-left: 10px;
  color: #fff;
  background-color: #c22424;
  margin-right: 88px;
}
button.nonAdmissionButton:hover {
  background-color: #e82d2d;
}

button.repeatButton {
  margin-left: 10px;
  color: #fff;
  background-color: #3e47d5;
}
button.repeatButton:hover {
  background-color: #5660ff;
}

.centerText {
  text-align: center;
}

.clickableRow {
  cursor: pointer;
}

.signature {
  max-height: 40px;
}
