.info {
  color: #4caf50;
}

.warning {
  color: #ffc107;
}

.error {
  color: #f44336;
}
