.notAdmitted {
  color: red;
}

.searchButton {
  width: 100%;
}

.filterContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
