.card {
  width: 400px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.success {
  color: green;
}

div.controlWrapper {
  padding: 8px;
}

.fullWidth {
  width: 100%;
}
