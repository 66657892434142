.paper {
  padding: 20px;
}

h6.nodeName {
  margin-bottom: 5px;
}

.usersTable {
  margin-bottom: 15px;
}
