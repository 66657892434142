tr.autoResultAdmittedBg {
  background-color: #e8f5e9;
}

tr.autoResultAdmittedBg:hover {
  background-color: #c8e6c9 !important;
}

tr.autoResultNotAdmittedBg {
  background-color: #ffebee;
}

tr.autoResultNotAdmittedBg:hover {
  background-color: #ffcdd2 !important;
}

.rowControls {
  display:flex;
  flex-direction: row;
  align-items: center;
}
