.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a.control, button.control, div.control, span.control {
  margin-top: 10px;
}

div.largeInput {
  height: 72px;
  margin-top: 0px;
  margin-bottom: 16px;
}

.buttons {  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.buttons > button {  
  width: 100%
}

.esiaLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #F2F6FA;
  height: 100vh;
}