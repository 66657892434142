.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tableContainer {
  width: 100%;
}

.floatRight {
  float: right;
}

.attentionRow {
  background-color: #f44336;
}

.attentionCell {
  font-weight: bold;
  text-decoration: underline;
}

.container {
  display: flex;
  gap: 10px;
}

.form {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  height: 60px;
}