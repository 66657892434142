.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tableContainer {
  width: 100%;
}

.floatRight {
  float: right;
}
