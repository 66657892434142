tr.awaiting {
  background-color: #fffde7;
}

tr.awaiting:hover {
  background-color: #fff9c4 !important;
}

.rowControls {
  display:flex;
  flex-direction: row;
  align-items: center;
}
