.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.userInfo {
  margin-left: 25px;
  margin-bottom: 20px;
}

.chartWrapper {
  width: 100%;
  height: 60vh;
}

.dialogTitle > h2 {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show {
  margin-top: -1em;
  margin-bottom: 1em;
}
