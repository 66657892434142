.hidden {
  display: none;
}

.container {
  position: relative;
}

.buttonContainer {
  float: right;
}
