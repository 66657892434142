.container {
  padding: 16px;
}

.row {
  display:flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.column1 {
  flex: 1;
}

.column2 {
  flex: 2;
}
