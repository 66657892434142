.container {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 480px;
  height: 360px;
  margin: 8px;
  padding: 8px;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video {
  width: 480px;
  height: 360px;
}

.title {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px;
}
