button.admissionButton {
  margin-left: 10px;
  color: #fff;
  background-color: #009100;
}
button.admissionButton:hover {
  background-color: #00bf00;
}

button.nonAdmissionButton {
  margin-left: 10px;
  color: #fff;
  background-color: #c22424;
}
button.nonAdmissionButton:hover {
  background-color: #e82d2d;
}

button.fillButton {
  margin-left: 10px;
  color: #fff;
  background-color: #3e47d5;
}
button.fillButton:hover {
  background-color: #5660ff;
}
